import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/pages/index.vue"),
  },
  {
    path: "/weixin",
    name: "weixin",
    component: () => import("@/pages/social/wechat.vue"),
  },
  {
    path: "/releases",
    name: "releases",
    component: () => import("@/pages/releases/changelog.vue"),
  },
  {
    path: "/post/:title",
    name: "post",
    component: () => import("@/pages/post/vocabularyIT.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      const allowedTitles = ["Vocabulary in Information Technology", "A Guide to Cultivating Charisma"];
      if (allowedTitles.includes(to.params.title)) {
        if (to.params.title === "Vocabulary in Information Technology") {
          to.matched[0].components.default = () => import("@/pages/post/vocabularyIT.vue");
        } else if (to.params.title === "A Guide to Cultivating Charisma") {
          to.matched[0].components.default = () => import("@/pages/post/charisma.vue");
        }
        next();
      } else {
        next({ name: "index" });
      }
    },
  },
  {
    path: "/index-dark",
    name: "indexdark",
    component: () => import("@/pages/index-dark.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
